<template>
  <section>
      <div class="content-header">
        <h2>Research on character strengths</h2>
      </div>
      <div class="content-wrapper">
        <p class="pb-2">Check out these five journal articles, which discuss character strengths.</p>
        <h4>Character matters: The mediational impact of self-regulation on PsyCap and academic performance</h4>
        <p class="pb-4">In <a href="https://www.tandfonline.com/doi/abs/10.1080/08832323.2021.1874856?journalCode=vjeb20" target="_blank">this paper</a>, a framework is presented in which the effect of positive psychological capital (PsyCap) on academics is best understood when considered with self-regulation. The study drew from 128 undergraduate business students, and the results indicate that self-regulation partially mediates the relationship between psychological capital and GPA.  Psychological capital - includes optimism, hope, resilience and efficacy.</p>
        <h4>A review of character strengths interventions in twenty-first-century schools: Their importance and how they can be fostered</h4>
        <p class="pb-4"><a href="https://link.springer.com/article/10.1007/s11482-018-9700-6" target="_blank">This paper</a> discusses the importance of character strengths to foster students’ capacity to fulfill their potential. It discusses the connections between 21st century competencies and character strengths, demonstrating the importance of promoting them in education, and mechanisms for fostering the development of character strengths. </p>
        <h4>VIA character strengths among U.S. college students and their associations with happiness, well-being, resiliency, academic success and psychopathology </h4>
        <p class="pb-4">In  <a href="https://www.tandfonline.com/doi/full/10.1080/17439760.2020.1752785" target="_blank">this study</a>, 759 college students completed the VIA-Inventory of Strengths scale to test the relationship between character strengths and various measures of well-being. Character strengths were mostly strongly related to positive measures of well-being.</p>
        <h4>Character strengths and first-year college students’ academic persistence attitudes: An integrative model</h4>
        <p class="pb-4"><a href="https://journals.sagepub.com/doi/10.1177/0011000018786950" target="_blank">This study</a> examined the mediating effects of academic integration on the association between character strengths and institutional commitment among 653 first year college students. The study concluded that character strengths may be important for understanding academic integration and persistence.</p>
        <h4>Virtues and character strengths related to approach coping strategies of college students</h4>
        <p class="pb-4"><a href="https://link.springer.com/article/10.1007%2Fs11218-015-9305-y" target="_blank">This study</a> aimed to describe and analyze virtues, character strengths, and coping strategies of college students. Ninety-one college students completed VIA-IS and Coping Responses Inventory-Adult. Strong positive correlations were found between the six virtues and three coping strategies. </p>
      </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
